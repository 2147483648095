


/* Layout */
form {
  [class^=col-] {
    > * {
      margin-bottom: var(--base-spacing-unit);
    }
  }

  label {
    width: 100% !important;
  }
  .submit {
    text-align: right;
  }

}

/* Design */
// .mdc-text-field:not(.mdc-text-field--focused) {
.mdc-text-field {
  @include textfield.ink-color(white);
  // @include textfield.placeholder-color($color-gray-light);
  @include textfield.label-color($color-gray-light);

  @include textfield.fill-color(transparent);
  @include textfield.bottom-line-color(white);
  @include textfield.hover-bottom-line-color($color-gray-light);
  @include textfield.shape-radius(0);

  @include textfield.outline-color(white);
  @include textfield.hover-outline-color($color-gray-light);
  @include textfield.outline-shape-radius(0);


}
.mdc-text-field-character-counter {
  color: $color-gray-light !important;
}
.mdc-button {
  // @include button.container-fill-color(white);
  @include button.shape-radius(0);
  @include button.outline-color($color-gray-light);
  

  &.ff-loading {
    opacity: .2;
  }
}