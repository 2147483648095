// --------------------------------------------------

// [1] Set the default `font-size` and `line-height` for the entire project,
//     sourced from our default variables. The `font-size` is calculated to exist
//     in ems, the `line-height` is calculated to exist unitlessly.
// [2] Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//     navigating between pages that do/do not have enough content to produce
//     scrollbars naturally.
// [3] Ensure the page always fills at least the entire height of the viewport.
// [4] Prevent certain mobile browsers from automatically zooming fonts.
// [5] Fonts on OSX will look more consistent with other systems that do not
//     render text using sub-pixel anti-aliasing.
html {
  font-family: var(--base-font-family, sans-serif);
  font-size: var(--base-font-size); // [1]
  line-height: var(--base-line-height); //[1]
  font-weight: var(--base-font-weight);
  background-color: var(--color-page-background);
  color: var(--color-text);
  overflow-y: scroll; // [2]
  min-height: 100%; // [3]
  -webkit-text-size-adjust: 100%; // [4]
  -ms-text-size-adjust: 100%; // [4]
  -moz-osx-font-smoothing: grayscale; // [5]
  -webkit-font-smoothing: antialiased; // [6]

  -webkit-tap-highlight-color: transparent;


  
  // @include media-query(screen-xs-max) {
  //   font-size: var(--base-font-size--xs); 
  // }
  
  @include media-query(screen-xl) {
    //font-size: calc(var(--base-font-size--xl) / 1rem * 1em); 
    font-size: var(--base-font-size--xl);
  }
}



// USED FROM _variables.scss

// --------------------------------------------------

// Headings 1–6.
#{headings()} { // [1]
  font-family: var(--headings__font-family);
  font-weight: var(--headings__font-weight);
  color: var(--color-brand);
  // letter-spacing: var(--letter-spacing);
  // text-transform: uppercase;
  line-height: 1.3;
}

// Paragraph, Lists
ul {
  margin-left: 0;
  list-style-type: disc;
  list-style-type: "→";
  list-style-type: "\2022  ";
  padding-left: 1rem;
}

// Code
code {
  color: var(--code__color);
}

pre {
  padding: 1rem;
  background: var(--pre__color-background);
  color: var(--pre__color);
  overflow-x: scroll;
}

small {
  font-size: 67.857%;
}



::selection {
	background: var(--color-brand);
  color: var(--selection-color);
  opacity: 1;
}


// .orange {
//   color: white;
//   background-color: var(--color-brand);
//   h2,h3 {
//     color: white;
//   }
// }