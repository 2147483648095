// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/SHARED
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------




ul, ol, dl,
blockquote, p, address,
hr,
fieldset, figure,
pre {    
  margin-bottom: var(--base-spacing-unit);
}


ul, ol, dd {
  margin-left: var(--base-spacing-unit);
}


// Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


// hyperlink
a {
  // color: var(--color-links);
  color: inherit;
  transition: color .1s ease;
  text-decoration: underline;
  &:hover {
    color: var(--color-gray-light);
  }
}

// images
figure {
  margin: 0 0 var(--base-spacing-unit) 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}


pre {
  @include make-col(12);
}

hr {
  height: 2rem;
  margin: 0;
  border: 0;
  background-color: var(--color-brand);
  header &,
  aside & {
    height: 1.5rem;
  }
}

.hidden_on_desktop {
  @include media-query(screen-md) {
    display: none;
  }
}
.hidden_on_mobile {
  display: none;
  @include media-query(screen-md) {
    display: unset;
  }
}




.mt {
  margin-top: var(--base-spacing-unit);
}
.mt2 {
  margin-top: 1.47rem;
}

.mb {
  margin-bottom: var(--base-spacing-unit);
}

.border_top {
  @include border-top;
}
.border_bottom {
  @include border-bottom;
}
h2.border_bottom {
  padding-bottom: var(--base-spacing-unit);
}

.swiss_made {
  position: absolute;
  top: .2rem;
  right: 0;
  max-width: 7rem;
  // @include media-query(screen-sm) {
  //   top: 4rem;
  // }
}



table {
  width: 100%;
  margin-top:  var(--base-spacing-unit--sm);
  margin-bottom: var(--base-spacing-unit);
  border-collapse: collapse;
  border-spacing: 0;
  th, td {
    @include border-top();
    padding: .2rem .5rem
  }
  tr:last-child {
    th, td {
      @include border_bottom();
     
    }
  }
  th {
    font-weight: normal;
    text-align: left;
    // margin-right: var(--base-spacing-unit);
  }
}

.download_block {
  text-decoration: none;
}