.referenzen__row {
  background-color: black;
}
.referenzen__col {
  margin: 1.5rem;
  @include media-query(screen-md) {
    margin-right: 0;
  }
  .flickity-viewport {
    transition: height 0.2s;
  }


  .carousel-cell {
    width: 100%; /* full width */
   /* height of carousel */
    margin-right: 10px;
    
  }
  hr {
    height: 1px;
    background: white;
  }

  blockquote {
    margin: 0;
  }

  p {
    margin: 1.5rem 2rem;
  }
  img {
    width: 100%;
  }
  cite {
    display: block;
    margin-left: 35%;
    margin-top: -5px;
  }
}


.bg_image {
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    object-fit: cover;
  }
}