/**
 * layout
 */
.videos {
  @include border-top;
  padding-top: var(--grid__gutter--half);
}

/** 
 * Preview image
 */
a[data-video-id] {
  display: block;
  text-decoration: none;
  .preview_container {
    position: relative;
  }
  figure {
    margin-bottom: 0;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;

    background-color: rgba(0,0,0,0);
    transition: background-color .3s ease;
    img {
      width: 40px;
      height: auto;
      transform: scale(1);
      transition: transform .3s ease;
    }
  }
  h3 {
    margin-top: .4rem;
    margin-bottom: 0;
    line-height: 1.4;
  }
  p {
    color: white;
  }
  &:hover {
    .overlay {
      background-color: rgba(0,0,0,0.2);
      img {
        transform: scale(1.1);
      }
    }
  }
}




/**
 * Modal
 */
.basicLightbox:after {
  content: "";
  position: absolute;
  top: 1.8rem;
  right: 1.8rem;
  width: 2em;
  height: 2em;
  background: url(/layout/SVG/close.svg);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}


@keyframes modal-video {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes modal-video-inner {
	from {
		transform: translate(0, 100px);
	}

	to {
		transform: translate(0, 0);
	}
}


.youtube_wrapper {
  position: relative;
  height: 0;

  width: 100vw;
  max-width: 800px;
  max-width: max(800px, 70vw);
  
  padding-bottom: 56.25%;

  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video-inner;
  transform: translate(0);
  transition: transform .3s ease-out;
  iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
}
