.mdc-top-app-bar {
  // height: 5.25rem;
  position: relative;
  @include media-query(screen-md) {
    height: 4rem;
    position: fixed;
  }
}

header {
  background-color: transparent !important;
  @include media-query(screen-md) {
    background-color: var(--color-page-background) !important;
  }
  
  
  .container {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    // background: #000;
  }
  nav {
    display: none;
    @include media-query(screen-md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    } 
  }
  

  .logo {
    padding: var(--grid__gutter--half) 0;
    margin: 0;
    a {
      display: block;
    }
    img {
      height: 4rem;
      width: auto;
    }

    @include media-query(screen-md) {
      position: absolute;
      right: var(--grid__gutter);
      top: 124%;
      img {
        width: 16rem;
        height: auto;
      }
    }
  }
  
  nav {
    display: none;
    @include media-query(screen-md) {
      display: inherit;
    }
  }
  

  @include media-query(screen-sm-max) {
    position: relative;
  }


}


.burger_container {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  height: 5.25rem;
  z-index: 10;
  pointer-events: none;
  
  mix-blend-mode: difference;
  @include media-query(screen-md) {
    display: none ;
  }
  .burger {
    outline: none !important;
    position: relative;
    top: -0.2rem;
    right: calc( -1 * var(--grid__gutter--half) );

    pointer-events: all;
    
  }
}







/**
 *  Burger
 */
$burger_box_height: 25px;
$burger_line_width: 40px;
$burger_line_height: 4px;

$burger_box_height_mobile: 30px;
$burger_line_width_mobile: 45px;
$burger_line_height_mobile: 5px;
.burger {
  // background-color: black; // as fallback for mix-blend-mode on old browsers
  transition: transform 0.5s ease-in-out;
  transform: translate3d(0, 0, 0);
  will-change: transform;

  


  .mdc-drawer--open & {
    transform: translate3d(-280px, 0, 0);
  }

  display: inline-block;
  padding: 1rem;
  z-index: 300;
  pointer-events: all;
  cursor: pointer;

  
  
  .burger_inner {
    position: relative;
    display: block;
    width: $burger_line_width;
    height: $burger_box_height;
    
  }

  .line {
    transition: transform 200ms cubic-bezier(0.63, 0.62, 0.48, 1.64);
    display: block;
    background: white;
    width: $burger_line_width;
    height: $burger_line_height;
    
    position: absolute;

    &.line_1 {
      top: 0;
    }
    &.line_2 {
      top: 50%;
    }
    &.line_3 {
      top: 100%;
    }
  }

  // &:not(.close):hover .line {
  //   &.line_1 {
  //     transform: translate3D(-5px, 0, 0);
  //   }
  //   &.line_3 {
  //     transform: translate3D(5px, -0, 0);
  //   }
  // }
}

.mdc-drawer--open ~ #wrapper .burger {
  .line_1 {
    transform: translateY($burger_box_height/2) translateX(0) rotate(45deg);
  }
  .line_2 {
    opacity: 0;
  }
  .line_3 {
    transform: translateY(-$burger_box_height/2) translateX(0) rotate(-45deg);
    
  }
}
