

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin border_top {
  border-top: 1px solid white;
}
@mixin border_bottom {
  border-bottom: 1px solid white;
}
