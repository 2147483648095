/**
 * drawer 
 */
 .mdc-drawer {
  border-color: rgba(0,0,0,.12);
  background-color: #fff;
  border-radius: 0 0 0 0;
  z-index: 6;
  width: 256px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  border-right-width: 1px;
  border-right-style: solid;
  overflow: hidden;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(.4,0,.2,1);
  -o-transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.mdc-drawer--modal {
  -webkit-box-shadow: 0 8px 10px -5px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12);
  box-shadow: 0 8px 10px -5px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12);
  left: 0;
  right: auto;
  display: none;
  position: fixed;
}
.mdc-drawer--modal.mdc-drawer--open {
  display: -ms-flexbox;
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(.4,0,.2,1);
  -o-transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.mdc-drawer--open+.mdc-drawer-scrim {
  display: block;
  background-color: rgba(0,0,0,.32);
}
