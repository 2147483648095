nav {
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  

  a {
    display: block;
    text-decoration: none;
  }
  a.active {
    color: var(--color-brand);
  }

  
  .nav_lang {
    a.active  {
      color: var(--color-gray-light);
    }
  }

  a {
    svg {
      position: relative;
      top: -.2rem;
      height: 1.6rem;
      width: auto;
    }
    &.active svg path {
      fill: var(--color-brand);
    }
  } 

  li:first-child {
    svg {
      display: none;
    }
    @include media-query(screen-md) {
      span {
        display: none;
      }
      svg {
        display: block;
      }
    }

  }
  
  


  header &  ul {
    display: flex;
    &.nav_main li {
      margin-right: 2rem;  
    }

    &.nav_lang li {
      margin-left: .5rem;  
    }
    // font-size: 1.2em;

    color: var(--color-default);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-big);
  }

  footer & {
    ul {
      display: flex;
      li {
        margin-right: 1rem;
      }
    }
  }
  



  aside & {
    color: var(--color-gray);
    line-height: 2;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-big);
    ul.nav_lang {
      display: flex;
      li {
        margin-right: .5rem;
      }
    }
  }
  
  
  
  
  
  

  
}


aside {
  .logo a {
    outline: none;
  }
}