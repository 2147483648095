// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *
  
//
// VARIABLES
// --------------------------------------------------

// VARIABLES
// NOTE: we use 'em' as unit as you get cross-browser problems, when you
// change the browser base font-size, see http://zellwk.com/blog/media-query-units/
// [1] in fact 66.625em = 1066px, but 1066px, but since we have a font-size increase at screen-xl-min,
//     we end up at ~1200px (see _page.scss)
//

// $wrapper-max: 66.625em !default; // ~1200px [1]
$wrapper-max:         66.625em !default; // ~1200px [1]
$wrapper-max:         76em; // ~1200px [1]
// $wrapper-max-md:      76em; 

// $breakpoints: will be used in _grid.scss to generate grid classes
$breakpoints: (
  xs: (
    min:        0,
    max:        (34.375em - 0.0625em), // 549px
  ),
  sm: (
    min:        34.375em, // 550px
    max:        (48em - 0.0625em), // 767px
  ),
  md: (
    min:        48em, // 768px
    max:        (58.75em - 0.0625em), // 939px
  ),
  lg: (
    min:        58.75em, // 940px
    // max:     (68.75em - 0.0625em), // 1099px
    max:        (87em - 0.0625em),
  ),
  xl: (
    // min:        68.75em, // 1100px
    min:        87em, //76em, // 1100px
    max:        "none"
  ),
) !default;


$additional_breakpoints: (
  menu: (
    min:        48em, // 768px
    max:        (48em - 0.0625em), // 767px
  )
) !default;

$all_breakpoints: map-merge($breakpoints, $additional_breakpoints);


//
// BASE/GRID
// --------------------------------------------------

// VARIABLES
html {
  --grid__columns:       12;
  --grid__gutter:        1.25rem; // -> 20px;  // 1.875rem; // 30px
  --grid__gutter--half:  calc(var(--grid__gutter) / 2);
}

$grid__columns:         12 !default;


/*
 gray #42454A
 gray-light: #6F6F6F
 gray-light-2: #CECECE
 brand #ff7321 (orange)
*/

// General Colors
$color-default:            #ffffff;
$color-gray:               #42454A; 
$color-gray-light:         #6F6F6F; 
$color-gray-light-2:       #CECECE;
$color-brand: #ff7321;

html {
  --color-default:            #ffffff;
  // --color-gray-dark:          #222;
  --color-gray:               #42454A; 
  --color-gray-light:         #6F6F6F; 
  --color-gray-light-2:       #CECECE;
  
  --color-brand:              #ff7321;
  --color-highlight:          var(--color-brand);

  
  // Page
  --color-page-background:    #42454A;
  --color-text:               var(--color-default);
  --color-text--inverted:     var(--color-page-background);
  
  // Links
  --color-links:              var(--color-highlight);
  
  // Buttons
  --color-btn:                var(--color-links);
  --color-btn-text:           var(--color-text--inverted);
  
  // Base Font Settings
  --base-font-size--xs:       1rem;
  --base-font-size:           1rem;
  --base-font-size--xl:       1.125rem;
  
  --base-line-height:         1.47; //1.625;
  --base-font-weight:         400;
  
  --base-font-family:         'IBM Plex Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
  --font-familiy-crimson:     'Crimson Text';
  
  // Base Spacing Units
  --base-spacing-unit:  1rem;
  
  --base-spacing-unit--xs:    calc(var(--base-spacing-unit) / 4);
  --base-spacing-unit--sm:    calc(var(--base-spacing-unit) / 2);
  
  --base-spacing-unit--lg:    calc(var(--base-spacing-unit) * 2);
  --base-spacing-unit--xl:    calc(var(--base-spacing-unit) * 4);
  
  --letter-spacing: .04em;


  // Base Border Settings
  --base-border-radius:       4px;
  --base-border-width:        1px;
  --base-border-color:        var(--color-gray-light);
  
  --base-border:              var(--base-border-width) solid var(--base-border-color);

  // headings
  --headings__font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
  --headings__font-weight: 600;
  --headings__color:       'currentColor';

  --paragraph__link-color:							var(--color-links);
  --code__color:            						var(--color-highlight);
  --selection-background:								var(--color-brand);
  --pre__color-background:  						var(--color-gray);
  --pre__color:             						var(--color-text--inverted);
  
  --selection-color: 										var(--color-text--inverted);
  
  --hr-border: 													var(--base-border);
  --paragraph__link-decoration:         underline;
  --paragraph__link-decoration--hover:  none;
}