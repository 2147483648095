
#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}



#main{
  // padding-top: 5rem;
  padding-top: 0;
  padding-bottom: 2rem;
}

.container,
#header .row,
#main,
#footer {
  @include wrapper-max;
  @include padding-default;

  // @include media-query(screen-xl) {
  //   @include wrapper-max($wrapper-max-md);
  // }
}

header .row,
.tagline figcaption {
  @include wrapper-max($wrapper-max);
  // @include media-query(screen-xl) {
  //   @include wrapper-max($wrapper-max-md);
  // }
}


.isAnchor {
  overflow: hidden;
  @include media-query(screen-md) {
    position: relative;
    top: 2rem;
    padding-top: 2rem;
  }
  
}



