

/** 
 *  FOOTER
 */
#footer {
  
  .nav_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-query(screen-md) {
      flex-direction: row;
      justify-content: space-between;
    }
    
    color: var(--color-gray-light-2);
    
    @include border_top;
    padding: .5rem 0 2rem;

    > ul {
      @include media-query(screen-sm-max) {
        padding-bottom: 1rem;
      }
    }

    
  }
  
  .copyright {
    color: var(--color-gray-light);
  }
}


.craft_info {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  font-size: .7rem
}